import React from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
  isLarge?: boolean;
}

const Button = ({ children, className, isLarge, ...props }: Props) => {
  return (
    <button
      {...props}
      className={`
         w-fit border border-aumniBlue/50 hover:bg-aumniWhite/10 hover:text-aumniBlue h-fit w-fit transition transition-all duration-500 backdrop-blur-lg ${className}
         ${
           isLarge
             ? "px-8 py-5 uppercase text-2xl md:px-12 md:py-6 md:text-3xl"
             : "px-6 py-5 uppercase text-2xl md:px-8 md:py-4 md:text-base"
         }
      `}
    >
      {children}
    </button>
  );
};

export default Button;
