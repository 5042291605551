import React from "react";
import { currencyFormatter } from "../../utils";
import { FundTypes } from "../../lib/types";

const FundsTable = ({
  totalCost,
  totalImpliedValue,
  assetClass,
  expenseRatio,
}: FundTypes) => {
  return (
    <table className="hidden md:table col-span-4 w-full text-center">
      <thead>
        <tr>
          <th>Initial Cost</th>
          <th>Current Value</th>
          <th>Asset Class</th>
          <th>Expense Ratio</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="font-mono">
            {totalCost && currencyFormatter.format(totalCost)}
          </td>
          <td className="font-mono">
            {totalImpliedValue && currencyFormatter.format(totalImpliedValue)}
          </td>
          <td>{assetClass}</td>
          <td className="font-mono">{expenseRatio}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default FundsTable;
