import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { currencyFormatter } from "../../utils";
import { CompaniesType, CompanyTypes } from "../../lib/types";

ChartJS.register(ArcElement, Tooltip, Legend);

const FundOverview = ({ companies }: CompaniesType) => {
  const data = {
    labels: companies.map(({ name }) => name),
    datasets: [
      {
        data: companies.map(({ cost }) => cost),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 0.4)",
          "rgba(54, 162, 235, 0.4)",
          "rgba(255, 206, 86, 0.4)",
          "rgba(75, 192, 192, 0.4)",
          "rgba(153, 102, 255, 0.4)",
          "rgba(255, 159, 64, 0.4)",
        ],
        borderWidth: 3,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="grid md:grid-cols-2 gap-8">
      <div className="flex py-16">
        <Doughnut data={data} options={options} />
      </div>
      <div className="flex items-center">
        <table className="h-2/3 w-full text-center">
          <thead>
            <tr>
              <th>Holdings</th>
              <th>% of ownership</th>
              <th>cost</th>
            </tr>
          </thead>
          {companies.map(
            ({ id, name, cost, ownershipPercentage }: CompanyTypes) => (
              <tbody key={id}>
                <tr className="my-8">
                  <td>{name}</td>
                  <td className="font-mono">{ownershipPercentage}%</td>
                  <td className="font-mono">
                    {cost && currencyFormatter.format(cost)}
                  </td>
                </tr>
              </tbody>
            )
          )}
        </table>
      </div>
    </div>
  );
};

export default FundOverview;
