import React from "react";
import { motion } from "framer-motion";

import { pageTransitionVariant } from "../styles/motion";

import { HomeParticles } from "../components/Particles";
import PageHero from "../components/PageHero";
import CTA from "../components/CTA";


const Home = () => {
  return (
    <motion.div
      className="relative"
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransitionVariant}
    >
      <PageHero
        title="Dominate with data."
        subtitle="Master the private capital markets with the most intelligent investment
        data available."
        hasButton
        hasBlurBorder
      />
      <CTA
        title="Schedule a demo today, and see how we can make you better."
        buttonText="Book a free demo"
      />
      <HomeParticles />
    </motion.div>
  );
};

export default Home;
