import React from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";

import fundMockData from "../../mocks/funds.json";
import { slugify, totalCost, totalImpliedValue } from "../../utils";
import { CompaniesType } from "../../lib/types";
import { pageTransitionVariant } from "../../styles/motion";

import { ChevronUpIcon } from "@heroicons/react/24/solid";
import FundHeader from "./FundHeader";
import Button from "../Button";
import FundDetailsTable from "./FundDetailsList";
import FundOverview from "./FundOverview";
import CTA from "../CTA";

const FundDetails = () => {
  const [toggleAccordion, setToggleAccordion] = React.useState(true);
  const { id } = useParams();

  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransitionVariant}
    >
      {fundMockData.map(
        ({
          id: fundId,
          name,
          ticker,
          expenseRatio,
          category,
          assetClass,
          companies,
        }: CompaniesType) => (
          <div key={fundId}>
            {id === (name && slugify(name)) && (
              <>
                <div className="relative my-8 grid md:grid-cols-2 gap-16 px-8 py-16">
                  <div>
                    <FundHeader ticker={ticker} name={name} />
                    <div className="pt-16 space-x-6 w-full flex">
                      <Button>Buy</Button>
                      <Button>Compare</Button>
                    </div>
                  </div>
                  <FundDetailsTable
                    expenseRatio={expenseRatio}
                    category={category}
                    assetClass={assetClass}
                    totalCost={totalCost(companies)}
                    totalImpliedValue={totalImpliedValue(companies)}
                  />
                </div>
                <button
                  className="flex"
                  onClick={() => setToggleAccordion((curr) => !curr)}
                >
                  <h4 className="mr-3 uppercase">Overview</h4>
                  <ChevronUpIcon
                    className={`h-8 w-8 ${
                      toggleAccordion ? "rotate-180" : "rotate-0"
                    } transition`}
                  />
                </button>
                {toggleAccordion && <FundOverview companies={companies} />}
              </>
            )}
          </div>
        )
      )}
      <CTA
        title="Learn how you can get the most out of your investments."
        buttonText="See how it works"
      />
   </motion.div>
  );
};

export default FundDetails;
