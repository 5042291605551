import React from "react";
import Button from "./Button";

interface Props {
  title: string;
  buttonText?: string;
}

const Cta = ({ title, buttonText }: Props) => {
  return (
    <div className="my-64 space-y-14 text-center">
      <h2 className="text-6xl ">{title}</h2>
      {buttonText && <Button isLarge>{buttonText}</Button>}
    </div>
  );
};

export default Cta;
