import React from "react";

import { FundTypes } from "../../lib/types";

const FundHeader = ({ ticker, name }: FundTypes) => {
  return (
    <div className="col-span-2">
      <h2 className="absolute top-0 left-0 z-10 text-9xl text-white/10 transition duration-700 transition-color group-hover:scale-110 group-hover:text-white/20">
        {ticker}
      </h2>
      <h2 className="relative z-50 transition duration-500 transition-color group-hover:text-aumniBlue group-hover:scale-105">
        {name}
      </h2>
    </div>
  );
};

export default FundHeader;
