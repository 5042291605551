export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export const dateFormatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

export const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

export const totalCost = (arr: any[]) =>
  arr.map(({ cost }) => cost).reduce((acc, amount) => acc + amount);

export const totalImpliedValue = (arr: any[]) =>
  arr
    .map(({ impliedValue }) => impliedValue)
    .reduce((acc, amount) => acc + amount);
