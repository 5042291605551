import React from "react";

import { currencyFormatter } from "../../utils";
import { FundTypes } from "../../lib/types";

const FundDetailsList = ({
  expenseRatio,
  assetClass,
  category,
  totalCost,
  totalImpliedValue,
}: FundTypes) => {
  return (
    <div className="grid grid-cols-2 gap-16">
      <ul className="flex flex-col justify-between">
        <li>
          Management Style <span className="block li-table-detail">Index</span>
        </li>
        <li>
          Asset Class
          <span className="block li-table-detail">{assetClass}</span>
        </li>
        <li>
          Category <span className="block li-table-detail">{category}</span>
        </li>
      </ul>
      <ul className="flex flex-col justify-between">
        <li>
          Expense Ratio
          <span className="block font-mono li-table-detail">
            {expenseRatio}
          </span>
        </li>
        <li>
          Total Cost
          <span className="block font-mono li-table-detail">
            {totalCost && currencyFormatter.format(totalCost)}
          </span>
        </li>
        <li>
          Current Value
          <span className="block font-mono li-table-detail">
            {totalImpliedValue && currencyFormatter.format(totalImpliedValue)}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default FundDetailsList;
