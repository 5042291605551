import React from "react";
import fundMockData from "../mocks/funds.json";

import { Link } from "react-router-dom";
import { CompaniesType } from "../lib/types";
import { slugify, totalCost, totalImpliedValue } from "../utils";
import FundsTable from "../components/Funds/FundsTable";
import FundHeader from "../components/Fund/FundHeader";

import { motion } from "framer-motion";
import { pageTransitionVariant } from "../styles/motion";
import PageHero from "../components/PageHero";

const Funds = () => {
  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransitionVariant}
    >
      <PageHero
        title="This is Aumni Innovation."
        subtitle="Using the latest in AI and machine learning technology, the AumniSphere analyzes your investment data with unprecedented speed, depth,
        and accuracy, so you can feel 100% confident in your decisions."
      />
      {fundMockData.map(
        ({
          id,
          name,
          ticker,
          expenseRatio,
          companies,
          assetClass,
        }: CompaniesType) => {
          return (
            name && (
              <Link to={`/fund/${slugify(name)}`} key={id}>
                <div className="relative my-8 grid md:grid-cols-6 border-b px-8 py-16 group border-b-1">
                  <FundHeader ticker={ticker} name={name} />
                  <FundsTable
                    totalCost={totalCost(companies)}
                    totalImpliedValue={totalImpliedValue(companies)}
                    assetClass={assetClass}
                    expenseRatio={expenseRatio}
                  />
                </div>
              </Link>
            )
          );
        }
      )}
    </motion.div>
  );
};

export default Funds;
