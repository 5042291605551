import React from "react";
import { NavLink } from "react-router-dom";

import Button from "./Button";

interface Props {
  title: string;
  subtitle: string;
  hasButton?: boolean;
  hasBlurBorder?: boolean;
}

const PageHero = ({ title, subtitle, hasButton, hasBlurBorder }: Props) => {
  return (
    <div className="relative my-24">
      {hasBlurBorder && (
        <div className="absolute inset-0 z-10 h-full md:bg-gray-500/10 md:backdrop-blur-sm md:border border-aumniWhite/20 rounded-xl" />
      )}
      <div className="space-y-12 relative z-50 md:p-16">
        <h1>{title}</h1>
        <h3>{subtitle}</h3>
        {hasButton && (
          <NavLink className="block w-fit" to="/funds">
            <Button isLarge>View Funds</Button>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default PageHero;
