// @ts-nocheck
import React from "react";
import Particles from "react-particles";
import { Container, Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";

const HomeParticles = ({ init, loaded }: any) => {
  const particlesInit = React.useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = React.useCallback(
    async (container: Container | undefined) => {},
    []
  );

  const options = {

    fpsLimit: 120,
    background: {
      image: "",
      position: "50% 50%",
      repeat: "no-repeat",
      size: "cover",
    },
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        onHover: {
          enable: true,
          mode: "repulse",
          parallax: {
            enable: true,
            force: 60,
          },
        },
        resize: true,
      },
      modes: {
        push: {
          quantity: 5,
        },
        repulse: {
          distance: 200,
          duration: 0.1,
        },
      },
    },
    particles: {
      color: {
        value:  "#ffffff",
      },
      links: {
        color: {
          value:  "#ffffff",
        },
        distance: 150,
        enable: true,
        opacity: 0.3,
      },
      move: {
        direction: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: false,
        speed: 0.2,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 1200,
        },
        value: 80,
      },
      opacity: {
        value: 0.25,
      },
      shape: {
        type: "circle",
      },
      size: {
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
  };

  return (
    <div>
      <Particles
        id="tsparticles"
        options={options}
        init={particlesInit}
        loaded={particlesLoaded}
      />
    </div>
  );
};

export { HomeParticles };
