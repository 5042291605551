import React from "react";
import { Link, NavLink, Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import "./styles/App.css";
import "./styles/Reset.css";
import "./styles/Typography.css";

import Funds from "./pages/Funds";
import Home from "./pages/Home";
import FundDetails from "./components/Fund/FundDetails";
import Button from "./components/Button";
import Navigation from "./components/Navigation";
import {HomeModernIcon} from "@heroicons/react/24/outline";

const NoMatch = () => (
  <div className="text-9xl text-center mt-[20vh]">
    404
    <NavLink className="block" to="/">
      <Button isLarge>Home</Button>
    </NavLink>
  </div>
);

const App = () => {
  return (
    <main className="py-16 mx">
      <header>
        <Link to="/">
          <HomeModernIcon className="w-16 h-16 text-white hover:text-aumniBlue"/>
        </Link>
        <Navigation />
      </header>
      <AnimatePresence>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/funds" element={<Funds />} />
          <Route path="/fund/:id" element={<FundDetails />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </AnimatePresence>
    </main>
  );
};

export default App;
