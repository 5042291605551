import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import fundMockData from "../mocks/funds.json";
import { FundTypes } from "../lib/types";
import { slugify } from "../utils";

const Navigation = () => {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [navIsOpen, setNavIsOpen] = React.useState(false);

  React.useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (navIsOpen && ref.current && !ref.current.contains(e.target)) {
        setNavIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [navIsOpen]);

  return (
    <nav
      className="hidden md:flex absolute h-auto top-24 right-[7vw] z-50"
      ref={ref}
    >
      <div className="relative" onClick={() => setNavIsOpen((curr) => !curr)}>
        <p className="cursor-pointer text-right transition transition-colors duration-500 hover:text-aumniBlue">
          Your Portfolio
        </p>
        <AnimatePresence>
          {navIsOpen && (
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.25 } }}
              exit={{ opacity: 0 }}
              className="mt-8 rounded-xl border p-12 text-right bg-aumniBlack border-aumniWhite/10 border-1 space-y-16"
            >
              <>
                {fundMockData.map(
                  ({ id, ticker, name }: FundTypes) =>
                    name && (
                      <li key={id} className="relative pb-0 group">
                        <Link to={`/fund/${slugify(name)}`} key={id}>
                          <p className="transition-all transition duration-500 group-hover:text-aumniBlue">
                            {name}
                          </p>
                          <p className="absolute bottom-0 left-0 text-5xl transition-all transition duration-500 text-aumniBlue/20 group-hover:scale-105">
                            {ticker}
                          </p>
                        </Link>
                      </li>
                    )
                )}
                <Link to={`/`}>
                  <p className="pt-12 transition transition-colors hover:text-aumniBlue">
                    HOME
                  </p>
                </Link>
              </>
            </motion.ul>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Navigation;
